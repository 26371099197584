import React, { useState } from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { LogoType, PageContext } from "@util/types";
import FooterSections from "@shared/footer/footerSections";
import { Container } from "@util/standard";
import { BlocksContent, Button, ContactForm, Image, Link, Drawer } from "@global";
import { useHandleQuery } from "@api";
import { ContactDetails } from "./teamLandingTemplate";
import { ArticleSection, Headerwrapper, PageWidthStyled } from "./blogInnerTemplate";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

export default function TeamInnerTemplate({ data, pageContext }: Props) {
  if (!data.sanityStaff) return null;

  const { name, seo, pageFooter, position, packageCategory, blockContent, bio, image } = data.sanityStaff;
  const handles = useHandleQuery();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <SEO seoData={seo} slug={pageContext.pagePath} />
      <ArticleSection>
        <PageWidthStyled alignItems="flex-start">
          <Headerwrapper>
            <h1 className="h3">{name} {position}</h1>
            <Link internalLink={handles.team} linkText="Back to Team" />
          </Headerwrapper>
          <ContactDetails>
            <BlocksContent data={blockContent} type={packageCategory as LogoType} />
          </ContactDetails>
          <BlocksContent data={bio} type={packageCategory as LogoType} margin="35px 0 0" tabletMargin="20px 0 0" />
          <Button onClick={() => setIsOpen(!isOpen)} margin="33px 0 0" linkText={`Get in Contact with ${name}`} />
          {image &&
            <Container margin="65px 0 0" tabletMargin="30px 0 0">
              <Image data={image} />
            </Container>
          }
        </PageWidthStyled>
      </ArticleSection>
      <FooterSections pageFooter={pageFooter} />
      <Drawer visible={isOpen} onClose={() => setIsOpen(false)}>
        <h5>Fill out your details and {name} will be in contact</h5>
        <ContactForm isSingleColumn subject={`Enquiry form submission to ${name}`} />
      </Drawer>
    </>
  );
}

export const query = graphql`
  query TeamInnerQuery($slug: String) {
    sanityStaff(slug: {current: {eq: $slug}}) {
      name
      position
      packageCategory
      seo {
        ...sanitySeo
      }
      blockContent {
        ...sanityBlockContent
      }
      bio {
        ...sanityBlockContent
      }
      image {
        ...sanityImageConstrained
      }
      pageFooter {
        ...sanityPageFooter
      }
    }
  }
`;
